import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'

const InteractorTransactionFundBillAdjustmentLinesCreate = () => {
  const transactionFundBillAdjustmentLinesCreate = useCallback((body, loading) => {
    const payload = {
      ...body,
    }
    confirmationAlert({
      message: getTranslate('message.confirm_process'),
      icon: 'question',
    }).then((result) => {
      if (result === true) {
        ApiService.jsonRequest(
          '/transaction/fund-bill-adjustment/lines-create',
          payload,
          (response) => {
            loading(false)
            if (response.code === 7201) {
              showAlert({
                icon: 'success',
                message: getTranslate('message.successfully_created'),
                timer: 2000,
                redirect: `/transaction/order-adjustment/update?uid=${payload.bill_adjustment_header_uid}`,
              })
            } else {
              showAlert({
                icon: 'error',
                message: getMessage(response?.msg),
                timer: 2000,
              })
            }
          },
        )
      } else {
        loading(false)
      }
    })
  }, [])

  return { transactionFundBillAdjustmentLinesCreate }
}

export default InteractorTransactionFundBillAdjustmentLinesCreate
