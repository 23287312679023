import { MENU_LIST } from './action'

export const StoreMenu = (data) => {
  return async (dispatch) => {
    dispatch({
      type: MENU_LIST,
      data,
    })
  }
}
