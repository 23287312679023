import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorMasterSubinventorySearch = () => {
  const masterSubinventorySearch = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/warehouse/master-subinventory/search', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])
  return { masterSubinventorySearch }
}

export default InteractorMasterSubinventorySearch
