import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionFundBillAdjustmentLinesUpdate = () => {
  const transactionFundBillAdjustmentLinesUpdate = useCallback((body, loading) => {
    const payload = {
      ...body,
    }
    const isAlert = body.alert ?? false
    ApiService.jsonRequest(
      '/transaction/fund-bill-adjustment/lines-update',
      payload,
      (response) => {
        loading(false)
        if (response.code === 7200) {
          if (isAlert) {
            showAlert({
              icon: 'success',
              message:
                payload.is_deleted === 'Y'
                  ? getTranslate('message.successfully_deleted')
                  : getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: `/transaction/order-adjustment/update?uid=${payload.bill_adjustment_header_uid}`,
            })
          }
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      },
    )
  }, [])
  return { transactionFundBillAdjustmentLinesUpdate }
}

export default InteractorTransactionFundBillAdjustmentLinesUpdate
