import { DASHBOARD_REPORT } from './action'
import { getItem } from '../../utility/Session'

const initialState = {
  dashboard: getItem('mgk-dashboard'),
}

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_REPORT:
      return {
        ...state,
        dashboard: action.data,
      }
    default:
      return state
  }
}
