import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionOrderApproval = () => {
  const transactionApproval = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/transaction/order-header/approval', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
        showAlert({
          icon: 'success',
          message: getTranslate('message.successfully_updated'),
          timer: 2000,
          reload: true,
        })
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
          redirect: '/transaction/order/manage',
        })
      }
    })
  }, [])

  return { transactionApproval }
}

export default InteractorTransactionOrderApproval
