import { SHIPPING_FORM } from './action'

const initialState = {
  form_shipping: {
    corporate_uid: '',
    order_header_uid: '',
    warehouse_uid: '',
    note: '',
    delivery_lines: [],
  },
}

export default function ShippingReducer(state = initialState, action) {
  switch (action.type) {
    case SHIPPING_FORM:
      return {
        ...state,
        form_shipping: action.data,
      }
    default:
      return state
  }
}
