import React from 'react'
import { connect } from 'react-redux'
import { NAV_TYPE_TOP } from 'constant/interfaces/ThemeInitialize'
import utils from 'utility/Utils'
import MenuContent from './MenuContent'
import PropTypes from 'prop-types'

export const TopNav = ({ topNavColor, localization = true }) => {
  const props = { topNavColor, localization }
  return (
    <div
      className={`top-nav ${utils.getColorContrast(topNavColor)}`}
      style={{ backgroundColor: topNavColor }}
    >
      <div className="top-nav-wrapper">
        <MenuContent type={NAV_TYPE_TOP} {...props} />
      </div>
    </div>
  )
}

TopNav.propTypes = {
  topNavColor: PropTypes.any,
  localization: PropTypes.any,
}

const mapStateToProps = ({ theme }) => {
  const { topNavColor } = theme
  return { topNavColor }
}

export default connect(mapStateToProps)(TopNav)
