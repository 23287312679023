import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { showAlert } from 'utility/SweetAlert'

const InteractorWarehouseDeliveryLinesPickupHistory = () => {
  const pickupHistory = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/warehouse/delivery-lines/pickup-history', payload, (response) => {
      if (response.code === 7200 && response.data) {
        callback(response.data)
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
      loading(false)
    })
  }, [])
  return { pickupHistory }
}

export default InteractorWarehouseDeliveryLinesPickupHistory
