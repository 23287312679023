import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{' '}
        <span className="font-weight-semibold color-primary">{`${process.env.REACT_APP_NAME}`}</span>
        . All rights reserved.
      </span>
      PT. Widea Dagang Nusantara
    </footer>
  )
}
