import { MENU_ACCESS } from './action'

export const StoreAccess = (data) => {
  return async (dispatch) => {
    dispatch({
      type: MENU_ACCESS,
      data,
    })
  }
}
