import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorTransactionFundBillAdjustmentLinesSearch = () => {
  const transactionFundBillAdjustmentLinesSearch = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest(
      '/transaction/fund-bill-adjustment/lines-search',
      payload,
      (response) => {
        loading(false)
        if (response.code === 7200) {
          callback(response.data)
        } else {
          callback(null)
        }
      },
    )
  }, [])
  return { transactionFundBillAdjustmentLinesSearch }
}

export default InteractorTransactionFundBillAdjustmentLinesSearch
