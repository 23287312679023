import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Auth from 'routes/auth'

export const AuthLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={Auth} />
      </Switch>
    </div>
  )
}

export default AuthLayout
