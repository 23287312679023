import { REPORT_WAREHOUSE_ITEM, REPORT_WAREHOUSE_ITEM_TYPE } from './action'

const initialState = {
  item: [],
  itemType: '',
}

export default function TowerReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_WAREHOUSE_ITEM:
      return {
        ...state,
        item: action.data,
      }
    case REPORT_WAREHOUSE_ITEM_TYPE:
      return {
        ...state,
        itemType: action.data,
      }
    default:
      return state
  }
}
