import React from 'react'
import { Form, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import { getRules } from 'constant/interfaces/Rules'
import { thousandSeparator } from 'utility/Helpers'

const InputCurrency = ({ options, callback }) => {
  const onTrigger = (value) => {
    callback({
      initial_field: options.initial_field,
      value: value,
    })
  }
  if (options.input_only) {
    return (
      <InputNumber
        key={options.initial_field}
        defaultValue={options?.default_value}
        min={options.min ?? 0}
        max={options.max ?? 0}
        disabled={options.disabled ?? false}
        placeholder={options?.placeholder ? options?.placeholder : options?.label}
        value={options?.value}
        formatter={(e) => thousandSeparator(e)}
        parser={(val) => {
          if (options?.type === 'comma') {
            return parseFloat(
              `${val}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'),
            ).toFixed(options?.comma_digit ?? 6)
          } else {
            val = parseFloat(`${val}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'))
            val = parseInt(val)
            return val
          }
        }}
        onChange={(val) => {
          onTrigger(val)
        }}
        onBlur={(val) => {
          if (options.onBlur) {
            options?.onBlur(val)
          }
        }}
      />
    )
  } else {
    return (
      <>
        <Form.Item
          {...options?.field}
          label={options.label}
          rules={
            options.rules ? options.rules : options.required ? getRules(['required:true']) : false
          }
          name={options.initial_field}
          initialValue={options.initial_value ?? 0}
          extra={options.extra ?? null}
          className={options.className ?? null}
        >
          <InputNumber
            key={options.initial_field}
            defaultValue={options?.default_value}
            min={options.min ?? 0}
            max={options.max ?? 0}
            disabled={options.disabled ?? false}
            placeholder={options.placeholder ? options.placeholder : options?.label}
            value={options?.value}
            formatter={(e) => thousandSeparator(e)}
            parser={(val) => {
              if (options?.type === 'comma') {
                return parseFloat(
                  `${val}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'),
                ).toFixed(options?.comma_digit ?? 6)
              } else {
                val = parseFloat(`${val}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'))
                val = parseInt(val)
                return val
              }
            }}
            onChange={(val) => {
              onTrigger(val)
            }}
            onBlur={(val) => {
              if (options.onBlur) {
                options?.onBlur(val)
              }
            }}
          />
        </Form.Item>
      </>
    )
  }
}

InputCurrency.propTypes = {
  options: PropTypes.any,
  callback: PropTypes.any,
}

export default InputCurrency
