import { getTranslate } from 'utility/MapperTranslate'
import { getPattern } from './Pattern'

// example:
// getRules(['required:true', 'min:1', 'max:3', 'maxLength:100|type:number', 'pattern:alphanumeric'])

function msgMinimumMaximum(key, val) {
  let msg = null
  if (key === 'min') {
    msg = getTranslate(`validation.minimum`) + ` (${val})`
  } else if (key === 'max' || key === 'maxLength') {
    msg = getTranslate(`validation.maximum`) + ` (${val})`
  }
  return msg
}

export const getRules = (options) => {
  let rules = []
  options.forEach((obj) => {
    if (obj.includes('|')) {
      const objPipe = {}
      const pipes = obj.split('|')
      pipes.forEach((pipe) => {
        const pipeText = pipe.split(':')
        let pipeValue = pipeText.length > 0 ? pipeText[1] : ''
        if (['min', 'max', 'maxLength'].includes(pipeText[0])) {
          pipeValue = Number(pipeValue)
        }
        objPipe[pipeText[0]] = pipeValue
        const msgMinMax = msgMinimumMaximum(pipeText[0], pipeValue)
        if (msgMinMax) {
          objPipe.message = msgMinMax
        }
      })
      rules.push(objPipe)
    } else {
      let objColon = {}
      const text = obj.split(':')
      if (text[0] === 'required' || text[0] === 'whitespace') {
        objColon[text[0]] = true
        objColon.message = getTranslate('message.cannot_empty')
      } else if (['min', 'max', 'maxLength'].includes(text[0])) {
        objColon[text[0]] = Number(text[1])
        objColon.message = msgMinimumMaximum(text[0], Number(text[1]))
      } else if (text[0] === 'pattern') {
        objColon[text[0]] = getPattern(text[1]).regex
        objColon.message = getPattern(text[1]).message
      }
      rules.push(objColon)
    }
  })
  return rules
}
