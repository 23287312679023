import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionOrderLinesBatchUpdate = () => {
  const transactionOrderLinesBatchUpdate = useCallback(
    (body, callback, loading, is_deleted = 'N') => {
      const payload = body
      const isAlert = body.alert ?? false
      ApiService.jsonRequest('/transaction/order-lines/update-batch', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          callback(response)
          if (isAlert) {
            showAlert({
              icon: 'success',
              message:
                is_deleted === 'Y'
                  ? getTranslate('message.successfully_deleted')
                  : getTranslate('message.successfully_updated'),
              timer: 2000,
            })
          }
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [],
  )
  return { transactionOrderLinesBatchUpdate }
}

export default InteractorTransactionOrderLinesBatchUpdate
