import React from 'react'
import { Col, Row } from 'antd'
import { getTranslate } from 'utility/MapperTranslate'
import FeatherIcon from 'feather-icons-react'
import { Link } from 'react-router-dom'

const CardSummary = (obj) => {
  const { items } = obj
  return (
    <>
      {items.length > 0 &&
        items.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Col xxl={8} xl={8} md={8} sm={24} xs={24} key={index}>
                <Row gutter={[15, 15]}>
                  <Col span={24}>
                    <div className="card-view-secondary">
                      <Row gutter={15}>
                        <Col span={24}>
                          <div className="card-statistics-content">
                            <div className="icon-wrapper">
                              <FeatherIcon
                                size={18}
                                icon={item?.iconFeather}
                                style={{ color: '#fff' }}
                              />
                            </div>
                            <div className="statistics">
                              <h5>{item?.title}</h5>
                              <h4>{item?.value}</h4>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="hl"></div>
                      <Row gutter={15}>
                        <Col span={24}>
                          <Link className="button-no-wrapper" to={item?.url}>
                            <p>{getTranslate('button.see_more')}</p>
                            <FeatherIcon icon="arrow-right-circle" size={18} />
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </React.Fragment>
          )
        })}
    </>
  )
}

export default CardSummary
