import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import { ConfigProvider } from 'antd'
import useBodyClass from 'hooks/useBodyClass'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import locale from 'antd/es/locale/id_ID'

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const Routes = (props) => {
  const { location, direction } = props
  const { account } = useSelector((state) => state.account)
  useBodyClass(`dir-${direction}`)

  return (
    <ConfigProvider locale={locale} direction={direction}>
      <Switch>
        <Route path="/auth/signin">
          <AuthLayout direction={direction} />
        </Route>
        <RouteInterceptor path={'/'} isAuthenticated={account}>
          <AppLayout direction={direction} location={location} />
        </RouteInterceptor>
      </Switch>
    </ConfigProvider>
  )
}

RouteInterceptor.propTypes = {
  children: PropTypes.any,
  isAuthenticated: PropTypes.any,
}

Routes.propTypes = {
  location: PropTypes.any,
  direction: PropTypes.any,
}

const mapStateToProps = ({ theme }) => {
  const { locale, direction } = theme
  return { locale, direction }
}

export default withRouter(connect(mapStateToProps)(Routes))
