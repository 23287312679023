import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorWarehouseDeliveryLinesReceipt = () => {
  const deliveryLinesReceipt = useCallback((body, loading, callback = () => {}) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/warehouse/delivery-lines/receipt', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        showAlert({
          icon: 'success',
          message:
            payload.is_deleted === 'Y'
              ? getTranslate('message.successfully_deleted')
              : getTranslate('message.successfully_updated'),
          timer: 2000,
        })
        callback(payload.delivery_lines_code)
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])
  return { deliveryLinesReceipt }
}

export default InteractorWarehouseDeliveryLinesReceipt
