import { Drawer } from 'antd'
import Styled from 'styled-components'

const StyledDrawer = Styled(Drawer)`
    @media only screen and (max-width: 768px) {
        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
        .ant-drawer-header{
            flex-wrap: wrap;
            row-gap: 8px;
        }
    }
`

export { StyledDrawer }
