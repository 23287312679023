import React, { useCallback, useEffect, useRef, useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { CSVLink } from 'react-csv'
import PropTypes from 'prop-types'
import { Button } from '../buttons/buttons'
import { getTranslate } from 'utility/MapperTranslate'
import { MAX_EXPORT_ROWS } from 'constant/interfaces/ConstantValue'
import { csvMappingData } from 'utility/MapperData'
import { confirmationAlert } from 'utility/SweetAlert'
import { valueFormatter } from 'utility/Helpers'

const DownloadCSV = ({ headers, data, filename, loading, onLoading, onResponse, options }) => {
  const csvLink = useRef()
  const [listHeader, setListHeader] = useState([])
  const [listData, setListData] = useState([])

  const handelDownload = useCallback(() => {
    if (!loading) {
      if (options.interactor === null) {
        onResponse(data)
      } else {
        let downloadPayload = { ...options.payload }
        if (options.payload.limit > MAX_EXPORT_ROWS) {
          downloadPayload.limit = MAX_EXPORT_ROWS
        }
        options.interactor(
          downloadPayload,
          (response) => {
            onResponse(response.items ?? [])
            onLoading(true)
          },
          () => null,
        )
      }
    }
  }, [data, loading, onLoading, onResponse, options])

  useEffect(() => {
    const mapping_csv = csvMappingData(headers, data)
    setListHeader(mapping_csv.header)
    setListData(mapping_csv.body)
    if (loading && listHeader.length > 0) {
      csvLink.current.link.click()
      onLoading(false)
    }
  }, [data, headers, listHeader.length, loading, onLoading])

  return (
    <>
      <Button
        raised
        size="small"
        type="primary"
        key="download-csv"
        loading={loading}
        onClick={async () => {
          if (!loading && options.payload.limit > MAX_EXPORT_ROWS) {
            await confirmationAlert({
              message:
                getTranslate('message.export_more_than_the_maximum') +
                ' ' +
                valueFormatter('number', MAX_EXPORT_ROWS),
              icon: 'question',
            }).then((confirm) => {
              if (confirm === true) {
                handelDownload()
                return
              }
            })
          } else {
            handelDownload()
          }
        }}
      >
        <FeatherIcon icon="download" size={14} />
        {getTranslate('button.download_csv')}
        <CSVLink
          headers={listHeader}
          data={listData}
          filename={filename}
          separator=";"
          uFEFF={false}
          ref={csvLink}
          target="_blank"
          enclosingCharacter={``}
        />
      </Button>
    </>
  )
}

DownloadCSV.propTypes = {
  headers: PropTypes.any,
  data: PropTypes.any,
  filename: PropTypes.any,
  loading: PropTypes.bool,
  onLoading: PropTypes.any,
  onResponse: PropTypes.any,
  options: PropTypes.any,
}

export default DownloadCSV
