import React from 'react'
import { connect, useSelector } from 'react-redux'
import SideNav from 'components/layouts/SideNav'
import TopNav from 'components/layouts/TopNav'
import { Loading } from 'components/Main'
import MobileNav from 'components/layouts/MobileNav'
import HeaderNav from 'components/layouts/HeaderNav'
import PageHeader from 'components/layouts/PageHeader'
import Footer from 'components/layouts/Footer'
import Board from 'routes/board'
import { Layout, Grid } from 'antd'
import PropTypes from 'prop-types'

import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from 'constant/interfaces/ThemeInitialize'
import utils from 'utility/Utils'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useLocation } from 'react-router-dom'
import { findByArray } from 'utility/Helpers'
import { showAlert } from 'utility/SweetAlert'
import { getTranslate } from 'utility/MapperTranslate'

const { Content } = Layout
const { useBreakpoint } = Grid

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const { account } = useSelector((state) => state.account)
  const { menu_list } = useSelector((state) => state.menu)
  const currentRouteInfo = utils.getRouteInfo(menu_list, location.pathname)
  const { pathname } = useLocation()
  const { menu_access } = useSelector((state) => state.access)
  const protect_path = menu_access ? findByArray(menu_access, 'menu_path', pathname) : null
  const unprotected_path = [
    '/account/profile',
    '/account/change-password',
    '/notification',
    '/tools/import-csv',
  ]
  if (!unprotected_path.includes(pathname)) {
    if (menu_access && !protect_path) {
      showAlert({
        icon: 'warning',
        message: getTranslate(`message.forbidden_access`),
        timer: 2000,
        redirect: '/',
      })
    }
  }

  if (account && account?.must_change_password === 'Y' && pathname !== '/account/change-password') {
    showAlert({
      icon: 'warning',
      message: getTranslate(`message.must_change_password`),
      timer: 3000,
      redirect: '/account/change-password',
    })
  }

  const screens = utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher()

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <Board />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>

      {isMobile && <MobileNav />}
    </Layout>
  )
}

AppLayout.propTypes = {
  navCollapsed: PropTypes.any,
  navType: PropTypes.any,
  location: PropTypes.any,
  direction: PropTypes.any,
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme
  return { navCollapsed, navType, locale }
}

export default connect(mapStateToProps)(React.memo(AppLayout))
