import React from 'react'
import AppBreadcrumb from 'components/layouts/AppBreadcrumb'
import PropTypes from 'prop-types'

export const PageHeader = ({ title, display }) => {
  return display ? (
    <div className="app-page-header">
      <h3 className="mb-0 mr-3 font-weight-semibold">{title}</h3>
      <AppBreadcrumb />
    </div>
  ) : null
}

PageHeader.propTypes = {
  title: PropTypes.any,
  display: PropTypes.any,
}

export default PageHeader
