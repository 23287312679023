import { SET_LOADING_SHOW, SET_LOADING_HIDE } from './action'

export const ShowLoading = () => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_SHOW,
      loading: true,
    })
  }
}

export const HideLoading = () => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_HIDE,
      loading: false,
    })
  }
}
