import { FETCH_ACCOUNT } from './action'

export const FetchAccount = (data) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_ACCOUNT,
      data,
    })
  }
}
