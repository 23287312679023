import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'

const InteractorTransactionFundBillAdjustmentHeaderCreate = () => {
  const transactionFundBillAdjustmentHeaderCreate = useCallback(
    (body, loading, redirect = null) => {
      const payload = {
        ...body,
      }
      confirmationAlert({
        message: getTranslate('message.confirm_process'),
        icon: 'question',
      }).then((result) => {
        if (result === true) {
          ApiService.jsonRequest(
            '/transaction/fund-bill-adjustment/header-create',
            payload,
            (response) => {
              loading(false)
              if (response.code === 7201) {
                let url = '/transaction/order-adjustment/manage'
                if (redirect != null) {
                  url = redirect
                }
                showAlert({
                  icon: 'success',
                  message: getTranslate('message.successfully_created'),
                  timer: 2000,
                  redirect: url,
                })
              } else {
                showAlert({
                  icon: 'error',
                  message: getMessage(response?.msg),
                  timer: 2000,
                })
              }
            },
          )
        } else {
          loading(false)
        }
      })
    },
    [],
  )

  return { transactionFundBillAdjustmentHeaderCreate }
}

export default InteractorTransactionFundBillAdjustmentHeaderCreate
