import { getTranslate } from 'utility/MapperTranslate'
import CustomerCSV from '../../assets/csv/template/template_customer.csv'
import CustomerAddressCSV from '../../assets/csv/template/template_customer_address.csv'
import PriceListCSV from '../../assets/csv/template/template_price_list.csv'

export const applicationInitial = () => {
  let rows = ['WEB_CMS', 'WEB_CORPORATE', 'MOBILE_CUSTOMER', 'MOBILE_CORPORATE']
  return rows
}

export const contentCategory = () => {
  let rows = [
    'ABOUT_US',
    'FAQ',
    'GUIDE_CUSTOMER',
    'GUIDE_SALES',
    'GUIDE_DRIVER',
    'TOC',
    'PRIVACY_POLICY',
  ]
  return rows
}

export const employeePositionType = () => {
  let rows = [
    'DRIVER',
    'SALES_STAFF',
    'SALES_MANAGER',
    'FINANCE_STAFF',
    'FINANCE_MANAGER',
    'WAREHOUSE_STAFF',
    'WAREHOUSE_MANAGER',
    'AUDIT_STAFF',
    'AUDIT_MANAGER',
    'IT_STAFF',
    'IT_MANAGER',
    'ADMIN_CORPORATE',
    'EXECUTIVE',
  ]
  return rows
}

export const yesOrNo = () => {
  let rows = [
    {
      key: 'Y',
      value: 'yes',
    },
    {
      key: 'N',
      value: 'no',
    },
  ]
  return rows
}

export const filterProductCategory = () => {
  let rows = [
    {
      key: 'PACKAGING',
      value: getTranslate('options.item_category.PACKAGING'),
    },
    {
      key: 'OLEIN',
      value: getTranslate('options.item_category.OLEIN'),
    },
    {
      key: 'BUNDLING',
      value: getTranslate('options.item_category.BUNDLING'),
    },
  ]
  return rows
}

export const filterCustomerAddressType = () => {
  let rows = [
    {
      key: 'SHIP_TO',
      value: getTranslate('options.address_type.SHIP_TO'),
    },
    {
      key: 'BILL_TO',
      value: getTranslate('options.address_type.BILL_TO'),
    },
  ]
  return rows
}

export const statusYesNo = () => {
  let rows = [
    {
      key: 'Y',
      value: getTranslate('options.yesorno.Y'),
    },
    {
      key: 'N',
      value: getTranslate('options.yesorno.N'),
    },
  ]
  return rows
}

export const billDateType = () => {
  let rows = [
    {
      key: 'invoice_issued',
      value: getTranslate('options.bill_date_type.invoice_issued'),
    },
    {
      key: 'bill_due',
      value: getTranslate('options.bill_date_type.bill_due'),
    },
  ]
  return rows
}

export const languages = () => {
  let rows = ['ID', 'EN']
  return rows
}

export const activeOrInactive = () => {
  let rows = ['ACTIVE', 'INACTIVE']
  return rows
}

export const accountStatus = () => {
  let rows = ['ACTIVE', 'INACTIVE', 'REQUEST']
  return rows
}

export const customerStatus = () => {
  let rows = ['ACTIVE', 'INACTIVE', 'REQUEST', 'REJECTED']
  return rows
}

export const allowedPayment = () => {
  let rows = ['FULL_PAYMENT', 'TOP']
  return rows
}

export const listAllowedItem = () => {
  let rows = ['ALL', 'PACKAGING', 'OLEIN']
  return rows
}

export const priceListStatus = () => {
  let rows = ['DRAFT', 'RELEASE', 'DONE', 'INACTIVE']
  return rows
}

export const discountStatus = () => {
  let rows = ['DRAFT', 'RELEASE', 'DONE', 'INACTIVE']
  return rows
}

export const categoryUOM = () => {
  let rows = ['DUS', 'PCS', 'KG', 'LITER', 'JERIGEN', 'TON', 'PAKET']
  return rows
}

export const customerAddressType = () => {
  let rows = ['SHIP_TO', 'BILL_TO']
  return rows
}

export const customerBusinessType = () => {
  let rows = ['CORPORATE', 'INDIVIDUAL']
  return rows
}

export const taxType = () => {
  let rows = ['INCLUDE', 'EXCLUDE']
  return rows
}

export const productCategory = () => {
  let rows = ['PACKAGING', 'BUNDLING', 'OLEIN']
  return rows
}

export const inventoryType = () => {
  let rows = ['INBOUND', 'OUTBOUND']
  return rows
}

export const deliveryType = () => {
  let rows = ['PICKUP', 'SHIP_BY_CORPORATE']
  return rows
}

export const priceMethod = () => {
  let rows = ['PRODUCT', 'LIVE_MARKET', 'SPREADING']
  return rows
}

export const driverType = () => {
  let rows = ['INTERNAL', '3PL', 'EXTERNAL']
  return rows
}

export const itemCategory = () => {
  let rows = ['PACKAGING', 'OLEIN']
  return rows
}

export const orderType = () => {
  let rows = ['PACKAGING', 'OLEIN']
  return rows
}

export const returHeaderStatus = () => {
  let rows = [
    'REQUEST',
    'CANCELLED',
    // 'REJECT',
    'PROCESSED',
    'COMPLETED',
    'APPROVED_BY_SALES',
    'APPROVED_BY_AUDIT',
  ]
  return rows
}

export const returLinesStatus = () => {
  let rows = ['REQUEST', 'CANCELLED', 'APPROVED']
  return rows
}

export const orderHeaderStatus = () => {
  let rows = [
    'WAITING_APPROVED_SALES',
    'WAITING_APPROVED_CUSTOMER',
    'WAITING_APPROVED_CORPORATE',
    'PROCESSED',
    // 'WAITING_TO_PICKED_UP',
    'SHIPPED',
    // 'DELIVERED',
    'COMPLETED',
    'HOLD',
    'CANCELLED',
  ]
  return rows
}

export const orderPaymentTerm = () => {
  let rows = [
    {
      name: '7_DAYS',
      days: 7,
      type: 'TOP',
    },
    {
      name: '10_DAYS',
      days: 10,
      type: 'TOP',
    },
    {
      name: '14_DAYS',
      days: 14,
      type: 'TOP',
    },
    {
      name: '21_DAYS',
      days: 21,
      type: 'TOP',
    },
    {
      name: '30_DAYS',
      days: 30,
      type: 'TOP',
    },
    {
      name: '35_DAYS',
      days: 35,
      type: 'TOP',
    },
    {
      name: '45_DAYS',
      days: 45,
      type: 'TOP',
    },
    {
      name: '50_DAYS',
      days: 50,
      type: 'TOP',
    },
    {
      name: '55_DAYS',
      days: 55,
      type: 'TOP',
    },
    {
      name: '60_DAYS',
      days: 60,
      type: 'TOP',
    },
  ]
  return rows
}

export const accountRole = () => {
  let rows = ['CUSTOMER', 'CORPORATE', 'CMS']
  return rows
}

export const discountCategory = () => {
  let rows = [
    'ITEM',
    'ITEM_PICKUP',
    'TRANSACTION',
    'TRANSACTION_MULTIPLE',
    'FULL_PAYMENT',
    'ITEM_BONUS',
    'ITEM_TRANSACTION',
  ]
  return rows
}

export const discountType = () => {
  let rows = ['PERCENTAGE', 'FIXED', 'MULTIPLE_ITEM']
  return rows
}

export const downPaymentType = () => {
  let rows = ['PERCENTAGE', 'FIXED']
  return rows
}

export const vehicleOwnership = () => {
  let rows = ['RENT', 'COMPANY']
  return rows
}

export const billCategory = () => {
  let rows = ['ORDER']
  return rows
}

export const categoryImportCsv = () => {
  let rows = [
    {
      category: 'CUSTOMER_ORACLE',
      template: CustomerCSV,
      pathModule: '/consumer/customer/manage',
    },
    {
      category: 'CUSTOMER_ADDRESS',
      template: CustomerAddressCSV,
      pathModule: '/consumer/address/manage',
    },
    {
      category: 'PRICE_LIST',
      template: PriceListCSV,
      pathModule: '/product/item/manage',
    },
  ]
  return rows
}

export const billStatus = () => {
  let rows = ['ISSUED', 'PAID', 'CANCELLED']
  return rows
}

export const deliveryHeaderStatus = () => {
  let rows = ['CREATED', 'PROCESSED', 'DONE', 'RECEIPT_DIFFERENCE', 'CANCELLED']
  return rows
}

export const deliveryLinesStatus = () => {
  let rows = ['CREATED', 'READY_TO_SHIP', 'PROCESSED', 'DELIVERED', 'REQUEST', 'CANCELLED']
  return rows
}

export const productItemStatus = () => {
  let rows = ['DRAFT', 'ACTIVE', 'INACTIVE']
  return rows
}

export const fundPaymentStatus = () => {
  let rows = ['RECEIVED_SALES', 'WAITING_APPROVAL_CORPORATE', 'APPROVED_CORPORATE', 'CANCELLED']
  return rows
}

export const otpStatus = () => {
  let rows = ['SENT', 'COMPLETED', 'EXPIRED']
  return rows
}

export const moveType = () => {
  let rows = [
    'CONVERSION',
    // 'REPACK',
    'SEGMENTATION',
  ]
  return rows
}

export const paymentMethod = () => {
  let rows = ['TRANSFER_CORPORATE', 'GIRO', 'CASH', 'BALANCE']
  return rows
}

export const returnType = () => {
  let rows = ['TUKAR_GULING', 'BALANCE']
  return rows
}

export const returReasonCategory = () => {
  let rows = ['CLOUDY', 'LEAKED']
  return rows
}

export const billAdjustmentAcceptance = () => {
  let rows = [
    // 'HAVE_BEEN_RECEIVED',
    'SEND_REPEATED',
    'DEDUCTION_AVAILABLE',
  ]
  return rows
}

export const driverSource = () => {
  let rows = ['INTERNAL', '3PL']
  return rows
}

export const orderSource = () => {
  let rows = [
    'GENERAL',
    'IMMEDIATE',
    // 'SPREADING',
    'GOVERNMENT',
    'CONTRACT',
  ]
  return rows
}

export const adjustmentType = () => {
  let rows = ['ADDITION', 'DEDUCTION']
  return rows
}

export const accountingCategory = () => {
  let rows = [
    'DEPOSIT_BALANCE',
    'DEPOSIT_BILL',
    // 'REFUND',
  ]
  return rows
}

export const accountingReceiveType = () => {
  let rows = ['TRANSFER_CORPORATE', 'GIRO', 'BALANCE', 'CM_RETUR', 'CM_PROMOTION']
  return rows
}

export const warehouseType = () => {
  let rows = [
    'DISTRIBUTION_CENTER',
    // 'CANVAS',
    'SPREADING',
  ]
  return rows
}

export const inventoryStatus = () => {
  let rows = ['DONE', 'PENDING']
  return rows
}

export const requestStatus = () => {
  let rows = ['REQUEST', 'DONE', 'REJECTED', 'CANCELLED']
  return rows
}

export const requestCategory = () => {
  let rows = ['GENERAL', 'PICKUP_ORDER']
  return rows
}

export const requestReferenceCategory = () => {
  let rows = ['DELIVERY_PICKUP', 'OTHER']
  return rows
}

export const documentCategory = () => {
  let rows = ['REQUEST_ITEM', 'DELIVERY_PICKUP', 'INVENTORY', 'DELIVERY_LINES']
  return rows
}

export const listTaxCode = () => {
  let rows = ['PPN_11', 'PPN_10', 'PPN_1', 'PPN_0']
  return rows
}

export const listCreditMemo = () => {
  let rows = ['CM_ADMINISTRATION', 'CM_OTHER']
  return rows
}

export const employeeStatus = () => {
  let rows = ['ACTIVE', 'INACTIVE', 'RESIGN']
  return rows
}
