import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Spin } from 'antd'
import AuthLayout from 'containers/authentication/Index'

const NotFound = () => {
  return <Redirect to="/" />
}

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route
          path="/auth/signin"
          component={lazy(() => import(`../../containers/authentication/SignIn`))}
        />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Suspense>
  )
}

export default AuthLayout(FrontendRoutes)
