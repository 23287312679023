import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorStorageFileUpload = () => {
  const uploadFile = useCallback((body, callback, progress) => {
    let payload = {
      ...body,
    }
    ApiService.formRequest(
      '/storage/file/upload',
      payload,
      (response) => {
        if (response.code !== 7200) {
          callback(null)
          showAlert({
            icon: 'error',
            message: getTranslate('response_message.failed_upload'),
            timer: 2000,
          })
        } else {
          callback(response.data)
        }
      },
      (percentage) => {
        progress(percentage)
      },
    )
  }, [])
  return { uploadFile }
}

export default InteractorStorageFileUpload
