import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorTowerReportDownload = () => {
  const downloadReport = useCallback((payload, path, filename, mimeType, callback, setLoading) => {
    ApiService.downloadRequest(path, payload, filename, mimeType, callback, setLoading)
  }, [])

  return { downloadReport }
}

export default InteractorTowerReportDownload
