import { Modal } from 'antd'
import Styled from 'styled-components'

const StyledModal = Styled(Modal)`
    .ant-modal-header .ant-modal-title {
        padding-right: 16px;
    }
    .ant-modal-footer{
        display:flex;
        justify-content:space-between;
    }
`

export { StyledModal }
