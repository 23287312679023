import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { useDispatch } from 'react-redux'
import { FetchAccount } from 'redux/account/creator'
import { setItem } from 'utility/Session'

const InteractorSSOAccountToken = () => {
  const dispatch = useDispatch()
  const syncToken = useCallback(
    (account, push_token) => {
      const payload = { ...account }
      payload.fcm_web = push_token
      payload.account_menu = null
      ApiService.jsonRequest('/sso/account/update', payload, (response) => {
        if (response.code === 7200) {
          let expires = new Date().getTime() + 8 * 3600 * 1000 // 8 Hours
          const account = {
            key: 'mgk-account',
            value: payload,
            expires,
          }
          setItem(account)
          dispatch(FetchAccount(payload))
        }
      })
    },
    [dispatch],
  )
  return { syncToken }
}

export default InteractorSSOAccountToken
