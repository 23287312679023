import React from 'react'
import PropTypes from 'prop-types'
import { getTranslate } from 'utility/MapperTranslate'
import { StyledDrawer } from './style'

const PopUpDrawer = (props) => {
  const {
    title = getTranslate('title.pickup_information'),
    width = 750,
    onClose = () => {},
    visible = false,
    bodyStyle = {
      paddingBottom: 80,
    },
    extra = <></>,
    footer = <></>,
    children = <></>,
  } = props

  return (
    <StyledDrawer
      title={title}
      width={width}
      onClose={onClose}
      bodyStyle={bodyStyle}
      visible={visible}
      extra={extra}
      footer={footer}
    >
      {children}
    </StyledDrawer>
  )
}

PopUpDrawer.propTypes = {
  title: PropTypes.string,
  width: PropTypes.any,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  bodyStyle: PropTypes.any,
  extra: PropTypes.any,
  footer: PropTypes.any,
  children: PropTypes.any,
}

export default PopUpDrawer
