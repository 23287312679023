import { Button } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { getTranslate } from 'utility/MapperTranslate'
import { StyledModal } from './style'

const PopUpModal = (props) => {
  const {
    buttonShow = false,
    buttonLabel = 'Open Modal',
    buttonClassName = '',
    callbackSuccess = () => {},
    callbackFail = () => {},
    children = <></>,
    modalTitle = '',
    confirmButtonName = getTranslate('button.ok'),
    cancelButtonName = getTranslate('button.cancel'),
    width = 'max(50%, 320px)',
    isModalOpen = false,
    setIsModalOpen = () => {},
    hasForm = false,
    hasFooter = true,
    disabledConfirm = false,
  } = props

  const handleOk = async () => {
    const result = (await callbackSuccess()) ?? true
    result && setIsModalOpen(false)
  }

  const handleCancel = () => {
    callbackFail()
    setIsModalOpen(false)
  }
  const showModal = () => {
    setIsModalOpen(true)
  }

  if (hasFooter) {
    return (
      <>
        {buttonShow && (
          <Button type="primary" onClick={showModal} className={`popup-btn ${buttonClassName}`}>
            {buttonLabel}
          </Button>
        )}
        <StyledModal
          title={modalTitle}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={confirmButtonName}
          cancelText={cancelButtonName}
          width={width}
          forceRender={hasForm}
          okButtonProps={{
            disabled: disabledConfirm,
          }}
        >
          {children}
        </StyledModal>
      </>
    )
  } else {
    return (
      <>
        {buttonShow && (
          <Button type="primary" onClick={showModal} className={`popup-btn ${buttonClassName}`}>
            {buttonLabel}
          </Button>
        )}
        <StyledModal
          title={modalTitle}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={confirmButtonName}
          cancelText={cancelButtonName}
          width={width}
          forceRender={hasForm}
          footer={false}
          okButtonProps={{
            disabled: disabledConfirm,
          }}
        >
          {children}
        </StyledModal>
      </>
    )
  }
}

PopUpModal.propTypes = {
  buttonShow: PropTypes.bool,
  buttonClassName: PropTypes.string,
  buttonLabel: PropTypes.any,
  callbackSuccess: PropTypes.func,
  callbackFail: PropTypes.func,
  cancelButtonName: PropTypes.string,
  confirmButtonName: PropTypes.string,
  children: PropTypes.any,
  hasForm: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  modalTitle: PropTypes.any,
  setIsModalOpen: PropTypes.func,
  width: PropTypes.any,
  footer: PropTypes.any,
  hasFooter: PropTypes.any,
  disabledConfirm: PropTypes.bool,
}

export default PopUpModal
