import { NOTIFICATION_DATA } from './action'

export const StoreNotification = (data) => {
  return async (dispatch) => {
    dispatch({
      type: NOTIFICATION_DATA,
      data,
    })
  }
}
