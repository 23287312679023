import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorTowerDashboardWeb = () => {
  const towerDashboardWeb = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/tower/dashboard/web', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])
  return { towerDashboardWeb }
}

export default InteractorTowerDashboardWeb
