import { NOTIFICATION_DATA } from './action'
import { getItem } from '../../utility/Session'

const initialState = {
  total_unread: getItem('mgk-notification-unread') ?? 0,
}

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_DATA:
      return {
        ...state,
        total_unread: action.data,
      }
    default:
      return state
  }
}
