import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constant/interfaces/ThemeInitialize'

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'id',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
}
