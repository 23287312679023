import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorProductPriceDiscountCustomer = () => {
  const priceDiscountCustomer = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/product/price-discount/customer', payload, (response) => {
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
      loading(false)
    })
  }, [])

  return { priceDiscountCustomer }
}

export default InteractorProductPriceDiscountCustomer
