import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ApiService from 'services/ApiService'
import { setItem } from 'utility/Session'
import { StoreNotification } from 'redux/notification/creator'

const InteractorManagementNotificationTotalUnread = () => {
  const account = useSelector((state) => state.account.account)
  const dispatch = useDispatch()
  const notificationUnread = useCallback(() => {
    const payload = {
      target: process.env.REACT_APP_APPLICATION === 'WEB_CMS' ? 'CMS' : 'CORPORATE',
      account_uid: account?.uid,
    }
    ApiService.jsonRequest('/management/notification/total-unread', payload, (response) => {
      if (response.code === 7200) {
        setItem({
          key: 'mgk-notification-unread',
          value: response.data.total_unread.toString(),
          expires: new Date().getTime() + 1 * 60 * 1000, // 1 minutes
        })
        dispatch(StoreNotification(response.data.total_unread.toString() ?? 0))
      }
    })
  }, [account?.uid, dispatch])
  return { notificationUnread }
}

export default InteractorManagementNotificationTotalUnread
