import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { PopUpVideoWrapper } from '../style'
import { PlayCircleOutlined } from '@ant-design/icons'
import { StyledModal } from './style'

const PopUpVideo = ({ url }) => {
  const [stateModal, setStateModal] = useState(false)
  return (
    <PopUpVideoWrapper>
      <ReactPlayer url={url} height="100px" width="100px" />
      <span className="video-mask-preview" onClick={() => setStateModal(!stateModal)}>
        <PlayCircleOutlined /> Preview
      </span>
      <StyledModal
        onCancel={() => setStateModal(!stateModal)}
        maskClosable
        visible={stateModal}
        footer={null}
        centered
        bodyStyle={{
          padding: 50,
          background: 'transparent',
        }}
        width={'80vw'}
      >
        <ReactPlayer url={url} controls height="100%" width="100%" />
      </StyledModal>
    </PopUpVideoWrapper>
  )
}

PopUpVideo.propTypes = {
  url: PropTypes.string,
}
export default PopUpVideo
