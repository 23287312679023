import React from 'react'
import { Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import { getTranslate } from 'utility/MapperTranslate'

const InputSelect = (options) => {
  return (
    <Select
      showSearch
      allowClear={options.allowClear}
      onClear={options.handleSearch}
      mode={options?.mode ?? false}
      disabled={options?.disabled ?? false}
      loading={options.loading}
      value={options.value}
      placeholder={options?.placeholder ?? ''}
      style={options?.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={options.handleSearch}
      onSelect={options.handleSelect}
      notFoundContent={options.loading ? <Spin size="small" /> : getTranslate('field.option_empty')}
      options={(options.data || []).map((d) => {
        let labels = []
        options?.field?.label.split('|').forEach((val) => {
          labels.push(d[val])
        })
        const obj = {
          value: d.uid,
          label: labels.join(' / '),
        }
        return obj
      })}
    />
  )
}

InputSelect.propTypes = {
  options: PropTypes.any,
}

export default InputSelect
