import React from 'react'
import { Col, Select } from 'antd'
import PropTypes from 'prop-types'
import { SelectWrapper } from '../style'

const SelectInput = ({ options, callback }) => {
  const onChange = (value) => {
    let val = options?.mode === 'multiple' ? value.join('|') : value
    callback({
      initial_field: options.initial_field,
      value: val ?? '',
    })
  }
  const getValueMode = (value) => {
    let default_value = value ?? null
    if (options?.mode === 'multiple') {
      default_value = value ? value.split('|') : []
    }
    return default_value
  }

  return (
    <>
      <Col>
        <SelectWrapper>
          <Select
            key={options.initial_field}
            defaultValue={getValueMode(options.defaultValue)}
            mode={options.mode ?? null}
            disabled={options?.disabled ?? false}
            showSearch
            allowClear={options?.allowClear ?? true}
            size="small"
            style={{ width: '100%' }}
            required={options.required ?? false}
            placeholder={options.placeholder ?? ''}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(inputValue, option) =>
              option?.title?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
            {...(options.value !== '' && {
              value: options.value,
            })}
          >
            <Select.Option value="" key="initial-default" disabled>
              {options.label}
            </Select.Option>
            {options.items?.length > 0 &&
              options.items.map((row) => (
                <Select.Option key={row.key} value={row.key} title={row.value}>
                  {row.value}
                </Select.Option>
              ))}
          </Select>
        </SelectWrapper>
      </Col>
    </>
  )
}

SelectInput.propTypes = {
  options: PropTypes.any,
  callback: PropTypes.any,
}

export default SelectInput
