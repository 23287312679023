import { getTranslate } from 'utility/MapperTranslate'

export const getPattern = (id) => {
  let pattern = {
    password: {
      regex: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!#$%\-_=+<>])([a-zA-Z0-9!#$%\-_=+<>]+)$/,
      message: getTranslate('message.pattern_incorrect_password'),
    },
    alphanumeric: {
      regex: /^([a-zA-Z0-9 ]+)$/,
      message: getTranslate('message.pattern_allowed_alphanumeric'),
    },
    numeric: {
      regex: /^[0-9]*$/,
      message: getTranslate('message.pattern_allowed_numeric'),
    },
    numeric_decimal: {
      regex: /^[\d]{0,10}(,\d{0,2})?$/,
      message: getTranslate('message.pattern_allowed_numeric_decimal'),
    },
    special_character: {
      regex: /^([a-zA-Z0-9 !@#$%&()_+'\-=\\[\];:\\|,.\\/?]+)$/,
      message: getTranslate('message.pattern_allowed_symbol') + ' !@#$%&()_+-=[];:|,./?\\',
    },
    percentage: {
      regex: /^(\d{0,2}(,\d{1,2})?|100(,00?)?)$/,
      message: getTranslate('message.pattern_allowed_percentage'),
    },
    conversion: {
      regex: /^(\d{0,6}(,\d{1,2})?|999999(,00?)?)$/,
      message: getTranslate('message.pattern_allowed_conversion'),
    },
    email: {
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: getTranslate('message.pattern_allowed_email'),
    },
  }
  return pattern[id] ?? null
}
