import React, { useCallback } from 'react'
import { Col, DatePicker } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePickerWrapper } from '../style'

const ExtraDatePicker = ({ options, callback }) => {
  const onChange = (dateMoment, dateString) => {
    let objCallback = {
      initial_field: options.initial_field,
    }
    let value = ''
    if (options.type !== 'range') {
      value = dateString
    } else {
      const ranges = [
        moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD'),
        moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD'),
      ]
      if (options?.withValue === true) {
        objCallback.valueRange = [moment(ranges[0], 'YYYY-MM-DD'), moment(ranges[1], 'YYYY-MM-DD')]
      }
      value = ranges.join(' - ')
    }

    objCallback.value = dateMoment ? value : ''
    callback(objCallback)
  }

  const disabledDate = useCallback(
    (d) => {
      const minDate = moment(options.minDate, 'DD-MM-YYYY')
      const maxDate = moment(options.maxDate, 'DD-MM-YYYY')

      if (d == null) {
        return null
      }

      return (
        (minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day')) ||
        (maxDate != null && d.isAfter(maxDate) && !d.isSame(maxDate, 'day'))
      )
    },
    [options.maxDate, options.minDate],
  )
  return (
    <>
      {options.type !== 'range' ? (
        <Col>
          <DatePickerWrapper>
            <DatePicker
              style={{ width: '100%' }}
              picker={options.type}
              disabledDate={options.minDate || options.maxDate ? disabledDate : false}
              key={options.initial_field}
              placeholder={options.placeholder}
              required={options.required ?? false}
              defaultValue={
                options.defaultValue ? moment(options.defaultValue, options.dateFormat) : null
              }
              allowClear={options.allowClear ?? true}
              format={options.dateFormat}
              onChange={onChange}
            />
          </DatePickerWrapper>
        </Col>
      ) : (
        <Col>
          <DatePickerWrapper>
            <DatePicker.RangePicker
              key={options.initial_field}
              required={options.required ?? false}
              allowClear={options.allowClear ?? true}
              disabledDate={options.minDate || options.maxDate ? disabledDate : false}
              defaultValue={
                options.defaultValue
                  ? [
                      moment(options.defaultValue?.start, options.dateFormat),
                      moment(options.defaultValue?.end, options.dateFormat),
                    ]
                  : null
              }
              {...(options?.withValue === true && {
                value: options.valueRange ?? null,
              })}
              format={options.dateFormat}
              onChange={onChange}
            />
          </DatePickerWrapper>
        </Col>
      )}
    </>
  )
}

ExtraDatePicker.propTypes = {
  options: PropTypes.any,
  callback: PropTypes.any,
}

export default ExtraDatePicker
