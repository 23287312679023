import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorTransactionOrderSearch = () => {
  const transactionOrderSearch = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/transaction/order-header/search', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])

  return { transactionOrderSearch }
}

export default InteractorTransactionOrderSearch
