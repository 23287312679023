import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorWarehouseDeliveryLinesUpdate = () => {
  const deliveryLinesUpdate = useCallback((body, loading, redirect = true, callback = () => {}) => {
    const payload = {
      ...body,
    }
    const isAlert = body.alert ?? false
    ApiService.jsonRequest('/warehouse/delivery-lines/update', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        if (isAlert) {
          showAlert({
            icon: 'success',
            message:
              payload.is_deleted === 'Y'
                ? getTranslate('message.successfully_deleted')
                : getTranslate('message.successfully_updated'),
            timer: 2000,
            redirect: redirect ? `/warehouse/delivery-schedule/manage` : false,
          })
        }
        callback(payload.delivery_lines_code)
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])

  const deliveryLinesUpdateDriver = useCallback(
    (body, loading, redirect = true, callback = () => {}) => {
      const payload = {
        ...body,
      }
      const isAlert = body.alert ?? false
      ApiService.jsonRequest('/warehouse/delivery-lines/update-driver', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          if (isAlert) {
            showAlert({
              icon: 'success',
              message:
                payload.is_deleted === 'Y'
                  ? getTranslate('message.successfully_deleted')
                  : getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: redirect ? `/warehouse/delivery-schedule/manage` : false,
            })
          }
          callback(payload.delivery_lines_code)
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [],
  )
  return { deliveryLinesUpdate, deliveryLinesUpdateDriver }
}

export default InteractorWarehouseDeliveryLinesUpdate
