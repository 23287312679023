import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'

const InteractorProductItemBundlingCreate = () => {
  const productItemBundlingCreate = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }

    confirmationAlert({
      message: getTranslate('message.confirm_process'),
      icon: 'question',
    }).then((result) => {
      if (result === true) {
        ApiService.jsonRequest('/product/bundling/create', payload, (response) => {
          loading(false)
          if (response.code === 7201) {
            callback(response)
            showAlert({
              icon: 'success',
              message: getTranslate('message.successfully_created'),
              timer: 2000,
            })
          } else {
            showAlert({
              icon: 'error',
              message: getMessage(response?.msg),
              timer: 2000,
            })
          }
        })
      } else {
        loading(false)
      }
    })
  }, [])
  return { productItemBundlingCreate }
}

export default InteractorProductItemBundlingCreate
