import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import Logo from './Logo'
import NavNotification from './NavNotification'
import NavProfile from './NavProfile'
import NavLanguage from './NavLanguage'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/theme/creator'
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'constant/interfaces/ThemeInitialize'
import utils from 'utility/Utils'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const { Header } = Layout

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
  } = props

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Link to={'/'}>
          <Logo logoType={navMode} />
        </Link>
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle()
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            <NavNotification />
            <NavLanguage />
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  )
}

HeaderNav.propTypes = {
  navCollapsed: PropTypes.any,
  mobileNav: PropTypes.any,
  navType: PropTypes.any,
  headerNavColor: PropTypes.any,
  toggleCollapsedNav: PropTypes.any,
  onMobileNavToggle: PropTypes.any,
  isMobile: PropTypes.any,
  currentTheme: PropTypes.any,
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } = theme
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme }
}

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav)
