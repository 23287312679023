import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as AntdIcons from '@ant-design/icons'

export class Icon extends Component {
  render() {
    const { keyName, type, className } = this.props
    let key = keyName
    if (!key && key !== '') {
      key = 'TableOutlined'
    }
    return <>{React.createElement(type ? type : AntdIcons[key], { className: className })}</>
  }
}

Icon.propTypes = {
  type: PropTypes.any,
  keyName: PropTypes.any,
  className: PropTypes.any,
}

export default Icon
