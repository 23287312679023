import React from 'react'
import { Row, Col } from 'antd'
import { Aside, Content } from './style'

const AuthLayout = (WraperContent) => {
  return function AuthLayout() {
    return (
      <Aside>
        <Row className="background-login">
          <Col lg={12} md={8} xs={24} className="auth-side-container">
            <div className="auth-side-content">
              <Content>
                <img style={{ width: '70%' }} src={require('../../assets/img/logo.png')} alt="" />
                <h1>
                  <i>Content Management System</i>
                </h1>
                <h4>PT. Widea Dagang Nusantara</h4>
              </Content>
            </div>
          </Col>

          <Col lg={12} md={16} xs={24}>
            <WraperContent />
          </Col>
          <img
            src={require('../../assets/img/auth/background-wave.png')}
            className="bottomShape"
            alt=""
          />
        </Row>
      </Aside>
    )
  }
}

export default AuthLayout
