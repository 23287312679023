import React from 'react'
import { Menu, Badge } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export const NavNotification = () => {
  const { total_unread } = useSelector((state) => state.notification)

  return (
    <Menu mode="horizontal">
      <Menu.Item key="notification">
        <Link to={'/notification'}>
          <Badge count={total_unread}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Link>
      </Menu.Item>
    </Menu>
  )
}

export default NavNotification
