import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorTowerImportCsv = () => {
  const importCsv = useCallback((body, callback, progress) => {
    let payload = {
      ...body,
    }
    ApiService.formRequest(
      '/tower/import/csv',
      payload,
      (response) => {
        if (response.code !== 7200) {
          callback(response)
        } else {
          callback(response.data)
        }
      },
      (percentage) => {
        progress(percentage)
      },
    )
  }, [])
  return { importCsv }
}

export default InteractorTowerImportCsv
