import React, { useState } from 'react'
import { Col, Input } from 'antd'
import PropTypes from 'prop-types'
import { TextWrapper } from '../style'

const InputText = ({ options, callback }) => {
  const [value, setValue] = useState(options.default_value ?? '')
  const onChange = (e) => {
    const { value } = e.target
    callback({
      initial_field: options.initial_field,
      value: value?.trim(),
    })
    setValue(value)
  }
  return (
    <>
      <Col>
        <TextWrapper>
          <Input
            allowClear
            key={options.initial_field}
            value={value}
            required={options.required ?? false}
            placeholder={options.placeholder}
            onChange={onChange}
          />
        </TextWrapper>
      </Col>
    </>
  )
}

InputText.propTypes = {
  options: PropTypes.any,
  callback: PropTypes.any,
}

export default InputText
