export const mimeType = {
  'image/jpeg': 'jpg|jpeg',
  'image/gif': 'gif',
  'image/png': 'png',
  'image/bmp': 'bmp',
  'image/tiff': 'tif|tiff',
  'image/x-icon': 'ico',
  'video/asf': 'asf|asx|wax|wmv|wmx',
  'video/avi': 'avi',
  'video/divx': 'divx',
  'video/x-flv': 'flv',
  'video/quicktime': 'mov|qt',
  'video/mpeg': 'mpeg|mpg|mpe',
  'text/plain': 'txt|asc|c|cc|h',
  'text/csv': 'csv',
  'text/tab-separated-values': 'tsv',
  'text/richtext': 'rtx',
  'audio/mpeg': 'mp3|m4a|m4b',
  'video/mp4': 'mp4|m4v',
  'audio/x-realaudio': 'ra|ram',
  'audio/wav': 'wav',
  'audio/ogg': 'ogg|oga',
  'video/ogg': 'ogv',
  'audio/midi': 'mid|midi',
  'audio/wma': 'wma',
  'application/pdf': 'pdf',
  'application/msword': 'doc|docx',
  'application/vnd.ms-powerpoint': 'pot|pps|ppt|pptx|ppam|pptm|sldm|ppsm|potm',
  'application/vnd.ms-excel': 'xla|xls|xlsx|xlt|xlw|xlam|xlsb|xlsm|xltm',
  'application/vnd.ms-access': 'mdb',
  'application/vnd.ms-project': 'mpp',
  'application/vnd.ms-word': 'docm|dotm',
  'application/vnd.openxmlformats-officedocument.presentationml': 'pptx|sldx|ppsx|potx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml': 'xlsx|xltx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml': 'docx|dotx',
  'application/onenote': 'onetoc|onetoc2|onetmp|onepkg',
  'application/x-shockwave-flash': 'swf',
  'application/x-tar': 'tar',
  'application/zip': 'zip',
  'application/x-gzip': 'gz|gzip',
  'application/x-msdownload': 'exe',
}
