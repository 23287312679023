import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'

const InteractorProductItemUpdate = () => {
  const productItemUpdate = useCallback((body, loading) => {
    const payload = {
      ...body,
    }
    const isAlert = body.alert ?? false
    confirmationAlert({
      message: getTranslate('message.confirm_process'),
      icon: 'question',
    }).then((result) => {
      if (result === true) {
        ApiService.jsonRequest('/product/item/update', payload, (response) => {
          loading(false)
          if (response.code === 7200) {
            if (isAlert) {
              showAlert({
                icon: 'success',
                message:
                  payload.is_deleted === 'Y'
                    ? getTranslate('message.successfully_deleted')
                    : getTranslate('message.successfully_updated'),
                timer: 2000,
                redirect: '/product/item/manage',
              })
            }
          } else {
            showAlert({
              icon: 'error',
              message: getMessage(response?.msg),
              timer: 2000,
            })
          }
        })
      }
    })
  }, [])
  return { productItemUpdate }
}

export default InteractorProductItemUpdate
