import { useState, useCallback } from 'react'
import InteractorMasterMenuSearch from './InteractorMasterMenuSearch'

const InteractorMasterMenuTree = () => {
  const [masterMenu, setMasterMenu] = useState([])
  const { menuSearch } = InteractorMasterMenuSearch()

  const menuTree = useCallback(
    (callback, expands, checkeds, application_initial = 'WEB_CMS', disabled, setLoading) => {
      if (masterMenu.length === 0) {
        menuSearch(
          {
            sort: 'menu_level asc, menu_sort_number asc',
            keywords: {
              text_search: '',
              application_initial: application_initial,
            },
            output: 'list',
          },
          (data) => {
            let menuExpand = []
            let menuChecked = []
            const recursion = (items, id = null, link = 'menu_parent_uid') => {
              return items
                .filter((item) => {
                  const comparison = item[link] || null
                  return comparison === id
                })
                .map((item) => {
                  let tree = {
                    title: item.menu_name,
                    key: item.uid,
                    menu_flag: item.menu_flag,
                    disabled,
                  }
                  if (item.menu_name === 'Beranda') {
                    tree.disabled = true
                    menuChecked.push(item.uid)
                  }
                  const children = recursion(items, item.uid)
                  if (children.length > 0) {
                    menuExpand.push(item.uid)
                    tree.children = children
                  }
                  return tree
                })
            }
            callback(recursion(data ?? []))
            expands(menuExpand)
            checkeds(menuChecked)
            setLoading(false)
            return setMasterMenu([])
          },
          () => null,
        )
      } else {
        setLoading(false)
      }
    },
    [masterMenu, menuSearch],
  )

  return { menuTree }
}

export default InteractorMasterMenuTree
