import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getTranslate } from 'utility/MapperTranslate'
import { removeItem } from 'utility/Session'
import { showAlert } from 'utility/SweetAlert'

const InteractorManagementNotificationReadAll = () => {
  const managementNotificationReadAll = useCallback((body, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/management/notification/read-all', payload, (response) => {
      loading(false)
      removeItem('mgk-notification-unread')
      showAlert({
        icon: 'success',
        message: getTranslate('message.successfully_updated'),
        timer: 2000,
        reload: true,
      })
    })
  }, [])
  return { managementNotificationReadAll }
}

export default InteractorManagementNotificationReadAll
