import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { showAlert } from 'utility/SweetAlert'

const InteractorVisitHistoryView = () => {
  const visitHistoryView = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/customer/visit-history/view', payload, (response) => {
      if (response.code === 7200) {
        callback(response.data)
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
          back: true,
        })
      }
      loading(false)
    })
  }, [])
  return { visitHistoryView }
}

export default InteractorVisitHistoryView
