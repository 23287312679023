import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../buttons'
import { getTranslate } from '../../../../utility/MapperTranslate'
import FeatherIcon from 'feather-icons-react'

const BackButton = ({ history, type }) => {
  return type === 'back' ? (
    <Button
      raised
      className="btn-primary"
      key="btn"
      size="small"
      type="primary"
      onClick={() => history.goBack()}
    >
      <FeatherIcon icon="chevron-left" size={14} /> <span>{getTranslate('button.back')}</span>
    </Button>
  ) : (
    <Button raised size="small" type="light" onClick={() => history.goBack()}>
      <FeatherIcon icon="x-circle" size={12} />
      {getTranslate('button.cancel')}
    </Button>
  )
}

BackButton.propTypes = {
  history: PropTypes.any,
  type: PropTypes.string,
}
export default BackButton
