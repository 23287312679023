import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Space } from 'antd'
import { Button } from 'components/extras/buttons/buttons'
import FeatherIcon from 'feather-icons-react'
import PropTypes from 'prop-types'
import DatePicker from '../datepicker/DatePicker'
import SelectInput from '../selectinput/SelectInput'
import TextInput from '../input/InputText'
import { Cards } from '../../content/card/ContentCard'
import { getTranslate } from '../../../utility/MapperTranslate'

const FilterForm = ({ options, callback, urlReset = '' }) => {
  const [payloadFilter, setPayloadFilter] = useState({})

  const onCallback = (obj) => {
    let objPayload = { ...payloadFilter }
    objPayload[obj.initial_field] = obj.value
    setPayloadFilter(objPayload)
  }

  const handleOnSubmit = () => callback(payloadFilter)

  useEffect(() => {
    if (Object.keys(payloadFilter).length === 0 && options && options.length > 0) {
      let objPayload = {}
      options.forEach((obj) => {
        if (obj?.initial_field) {
          let default_value = ''
          if (obj?.default_value !== '') {
            if (obj?.filter_target !== 'select') {
              default_value = obj?.default_value
            } else {
              default_value = obj?.defaultValue
            }
          }
          objPayload[obj.initial_field] = default_value
        }
      })
      setPayloadFilter(objPayload)
    }
  }, [options, payloadFilter])

  if (options && options.length > 0) {
    return (
      <>
        <Cards
          title={getTranslate('title.filter_search').toUpperCase()}
          bodypadding="15px 25px 15px 25px"
        >
          <Form layout="vertical">
            <Row gutter={[16, 24]}>
              {options.map((obj) => {
                if (obj?.filter_target) {
                  if (obj.filter_target === 'select') {
                    return (
                      <Col xs={24} md={6} key={obj.initial_field}>
                        <Form.Item
                          className="margin-bottom-0"
                          name={obj.initial_field}
                          label={obj.label ?? getTranslate('title.please_select')}
                          initialValue={''}
                        >
                          <SelectInput
                            key={obj.initial_field}
                            options={obj}
                            callback={onCallback}
                          />
                        </Form.Item>
                      </Col>
                    )
                  }
                  if (obj.filter_target === 'datepicker') {
                    return (
                      <Col xs={24} md={6} key={obj.initial_field}>
                        <Form.Item
                          className="margin-bottom-0"
                          name={obj.initial_field}
                          label={obj.label ?? getTranslate('field.period')}
                          initialValue={''}
                          style={{ width: '100%' }}
                        >
                          <DatePicker key={obj.initial_field} options={obj} callback={onCallback} />
                        </Form.Item>
                      </Col>
                    )
                  }
                  return (
                    <Col xs={24} md={6} key={obj.initial_field}>
                      <Form.Item
                        className="margin-bottom-0"
                        key={obj.initial_field}
                        name={obj.initial_field}
                        label={obj.label ?? getTranslate('title.keyword')}
                        initialValue={''}
                        style={{ width: '100%' }}
                      >
                        <TextInput key={obj.initial_field} options={obj} callback={onCallback} />
                      </Form.Item>
                    </Col>
                  )
                }
                return null
              })}
              <Col className="gutter-row" xl={6} md={6} sm={12} xs={24}>
                <Space style={{ width: '100%' }}>
                  <Form.Item
                    className="mobile-no-label"
                    key={'search'}
                    label={(options.length - 1) % 4 === 0 ? false : <></>}
                    initialValue={''}
                    style={{ width: '100%' }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        className="btn-primary"
                        onClick={() => handleOnSubmit()}
                      >
                        <FeatherIcon icon="search" size={12} />
                        {getTranslate('button.search')}
                      </Button>
                      {urlReset !== '' && (
                        <Button
                          type="light"
                          size="small"
                          className="btn-light"
                          onClick={() => (window.location.href = urlReset)}
                        >
                          <FeatherIcon icon="x-circle" size={12} />
                          {getTranslate('button.reset')}
                        </Button>
                      )}
                    </Space>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </Cards>
      </>
    )
  }
  return null
}

FilterForm.propTypes = {
  options: PropTypes.any,
  callback: PropTypes.any,
  urlReset: PropTypes.string,
}

export default FilterForm
