import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorProductPriceListSearch = () => {
  const priceListSearch = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/product/price-list/search', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])

  return { priceListSearch }
}

export default InteractorProductPriceListSearch
