import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { HideLoading } from '../../redux/loading/creator'
import ApiService from '../../services/ApiService'
import { setItem } from '../../utility/Session'
import { encryptCrypto } from '../../utility/Encryption'

const InteractorAuthorization = () => {
  const dispatch = useDispatch()
  const token = useCallback(() => {
    const sign = encryptCrypto(
      JSON.stringify({
        public_key: process.env.REACT_APP_PUBLIC_KEY,
        private_key: process.env.REACT_APP_PRIVATE_KEY,
      }).toString(),
    )
    const payload = {
      sign,
    }
    ApiService.jsonRequest('/token/request', payload, (response) => {
      setItem({
        key: 'mgk-indentity',
        value: response.access_token,
        expires: new Date(response.exp * 1000).getTime(),
      })
      dispatch(HideLoading())
      window.location.reload(false)
    })
  }, [dispatch])

  return { token }
}

export default InteractorAuthorization
