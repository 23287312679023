import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import {} from 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  AtomicBlockUtils,
  EditorState,
  convertToRaw,
  ContentState,
  // convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { InteractorStorageFileUpload } from 'interactors/Main'
import { TEXTEDITOR_TOOLBAR } from 'constant/interfaces/EditorConfig'

/**
 * Text Editor Props :
 * 1. Supported Toolbar ['inline', 'blockType', 'list', 'textAlign', 'link', 'embedded', 'image']
 * 2. optionFile={{type:PUBLIC,category:GENERAL,reference_uid:null}} This is optionFile default Value
 */
const TextEditor = (data) => {
  const { blockData, handleValue, toolbar, optionFile } = data

  const blocksFromHtml = htmlToDraft(blockData)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
  const { uploadFile } = InteractorStorageFileUpload()
  const [loading, setLoading] = useState(false)
  const [loadingEditor, setLoadingEditor] = useState(true)
  const [toolbarMenu, setToolbarMenu] = useState({ ...TEXTEDITOR_TOOLBAR })

  const onEditorStateChange = (editorValue) => {
    const value = convertToRaw(editorValue.getCurrentContent())
    handleValue(draftToHtml(value))
    setEditorState(editorValue)
  }

  const insertImage = (url) => {
    const currentContent = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'IMMUTABLE', { src: url })
    const entityKey = currentContent.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, { currentContent })
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
  }

  const onEditorUploadCallback = (file, opt) => {
    if (!loading) {
      setLoading(true)
      return new Promise((resolve) => {
        uploadFile(
          {
            type: opt?.type ?? 'PUBLIC',
            category: opt?.category ?? 'GENERAL',
            file,
            reference_uid: opt?.reference_uid ?? null,
          },
          (result) => {
            insertImage(result[0].file_url)
            resolve({ data: { link: result[0].file_url } })
          },
          (percentage) => setLoading(percentage === 100),
        )
      })
    }
    return null
  }

  useEffect(() => {
    if (loadingEditor && toolbar) {
      let newOptions = { ...toolbarMenu, options: [...toolbar] }
      setToolbarMenu(newOptions)
    }
    setLoadingEditor(false)
  }, [loadingEditor, toolbar, toolbarMenu])

  return (
    <>
      {!loadingEditor && (
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          wrapperStyle={{
            border: '1px solid #DDDDDD',
          }}
          editorStyle={{
            padding: '10px',
          }}
          toolbar={toolbarMenu}
          uploadCallback={(file) => onEditorUploadCallback(file, optionFile)}
          onEditorStateChange={onEditorStateChange}
        />
      )}
    </>
  )
}

export default TextEditor
