const { useCallback } = require('react')
const { default: ApiService } = require('services/ApiService')
const { getMessage } = require('utility/MapperResponse')
const { getTranslate } = require('utility/MapperTranslate')
const { showAlert } = require('utility/SweetAlert')

const InteractorWarehouseDeliveryItemsUpdate = () => {
  const deliveryItemsUpdate = useCallback(
    (
      body,
      loading,
      redirect = true,
      callback = () => null,
      path = `/warehouse/delivery-schedule/manage`,
    ) => {
      const payload = {
        ...body,
      }
      const isAlert = body.alert ?? false
      ApiService.jsonRequest('/warehouse/delivery-items/update', payload, (response) => {
        loading(false)
        if (response.code === 7201) {
          if (isAlert) {
            showAlert({
              icon: 'success',
              message:
                payload.is_deleted === 'Y'
                  ? getTranslate('message.successfully_deleted')
                  : getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: redirect ? path : false,
            })
            callback(response?.data)
          }
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
          callback(null)
        }
      })
    },
    [],
  )
  return { deliveryItemsUpdate }
}

export default InteractorWarehouseDeliveryItemsUpdate
