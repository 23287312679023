import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorTransactionFundBillAdjustmentHeaderSearch = () => {
  const transactionFundBillAdjustmentHeaderSearch = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest(
      '/transaction/fund-bill-adjustment/header-search',
      payload,
      (response) => {
        loading(false)
        if (response.code === 7200) {
          callback(response.data)
        } else {
          callback(null)
        }
      },
    )
  }, [])
  return { transactionFundBillAdjustmentHeaderSearch }
}

export default InteractorTransactionFundBillAdjustmentHeaderSearch
