import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorProductPriceSchemaCreate = () => {
  const priceSchemaCreate = useCallback((body, loading, callback) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/product/price-schema/create', payload, (response) => {
      loading(false)
      if (response.code !== 7201) {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      } else {
        callback(response.data)
        showAlert({
          icon: 'success',
          message: getTranslate('message.successfully_created'),
          timer: 2000,
        })
      }
    })
  }, [])
  return { priceSchemaCreate }
}

export default InteractorProductPriceSchemaCreate
