import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CloudUploadOutlined, FileDoneOutlined } from '@ant-design/icons'
import { Col, Typography } from 'antd'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'
import { DragDropWrapper } from '../style'

const FileUploadCSV = ({ setCsvFile, jsonCsvCB, headerCsvCB }) => {
  const [file, setFile] = useState(null)

  useEffect(() => {
    setCsvFile(file)
  }, [file, setCsvFile])

  const fileReader = new FileReader()

  const handleUpload = async (file) => {
    // const file_size = file.size
    // const size_mb = file_size / 1024 / 1024
    if (file.type !== 'text/csv') {
      setFile(null)
      showAlert({
        icon: 'error',
        message: getTranslate(`validation.only_allowed_extensions|CSV`),
        timer: 0,
      })
    } else {
      // if (file_size > 0 && size_mb > parseFloat(10)) {
      //   setFile(null)
      //   showAlert({
      //     icon: 'error',
      //     message: getTranslate(`validation.file_maximum|${10}|title.megabyte`),
      //     timer: 0,
      //   })
      // } else {
      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }
      setFile(file)
      fileReader.readAsText(file)
      // }
    }
  }

  const csvFileToArray = (string) => {
    const csvHeader = []
    const csvRows = []
    const header = string.slice(0, string.indexOf('\n')).split(/[\r;]/)
    header.forEach((element) => {
      if (element !== '') {
        csvHeader.push(element)
      }
    })
    const rows = string.slice(string.indexOf('\n') + 1).split(/[\r\n]/)
    rows.forEach((element) => {
      if (element !== '') {
        csvRows.push(element)
      }
    })
    if (csvHeader.length !== 8 && csvHeader.length !== 17 && csvHeader.length !== 31) {
      setFile(null)
      showAlert({
        icon: 'error',
        message: getTranslate('message.pattern_incorrect_format_csv'),
        timer: 0,
      })
    } else {
      if (csvRows[0].length > 0) {
        headerCsvCB(csvHeader)

        const toArray = csvRows.map((items) => {
          const values = []
          const valueSplit = items.split(/[;]/)

          if (valueSplit.indexOf('')) {
            values.push(...valueSplit)
          }

          const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index]
            return object
          }, {})
          return obj
        })

        const filterArray = toArray.filter((element) => {
          return element.no !== undefined
        })
        jsonCsvCB(filterArray)
      } else {
        setFile(null)
        showAlert({
          icon: 'error',
          message: getTranslate('message.data_empty'),
          timer: 0,
        })
      }
    }
  }
  return (
    <DragDropWrapper>
      <Col className="ant-upload ant-upload-select-picture-card">
        <input
          type="file"
          accept="text/csv"
          onInput={(e) => handleUpload(e.target.files[0])}
          value=""
        />
        <div className="button-face">
          {file ? (
            <>
              <FileDoneOutlined />
              <div>
                <Typography>{file.name}</Typography>
                <Typography></Typography>
                <Typography>
                  {getTranslate('title.file_size')}:{'  '}
                  {(file.size / 1024 / 1024).toFixed('2') > 0
                    ? `${(file.size / 1024 / 1024).toFixed('2')} Mb`
                    : `${file.size} byte`}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <CloudUploadOutlined />
              <div>
                <Typography>{getTranslate('title.upload_drag_drop')}</Typography>
                <Typography>{getTranslate('validation.only_allowed_extensions')} CSV</Typography>
                {/* <Typography>{getTranslate('validation.file_maximum')} 10 MB</Typography> */}
              </div>
            </>
          )}
        </div>
      </Col>
    </DragDropWrapper>
  )
}

FileUploadCSV.propTypes = {
  setCsvFile: PropTypes.func,
  jsonCsvCB: PropTypes.func,
  headerCsvCB: PropTypes.func,
}

export default FileUploadCSV
