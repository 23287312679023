import { useCallback } from 'react'
import { showAlert } from 'utility/SweetAlert'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'

const InteractorStorageFileDelete = () => {
  const deleteFile = useCallback((payload, callback) => {
    ApiService.jsonRequest('/storage/file/delete', payload, (response) => {
      if (response.code === 7200) {
        callback(response.data)
        showAlert({
          icon: 'success',
          message: getTranslate(`message.successfully_deleted`),
          timer: 2000,
        })
      } else {
        callback(null)
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])

  return { deleteFile }
}

export default InteractorStorageFileDelete
