import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import { Route, Switch } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { THEME_CONFIG } from './configs/AppConfig'
import {
  InteractorAuthorization,
  InteractorManagementNotificationTotalUnread,
  InteractorMasterMenuApplication,
} from './interactors/Main'
import { ShowLoading } from './redux/loading/creator'
import { getItem } from 'utility/Session'
import { useSelector, useDispatch } from 'react-redux'
import { Loading } from './components/Main'
import FirebaseNotification from './firebase/FirebaseNotification'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}
function App() {
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const { loading } = useSelector((state) => state.loading)
  const { token } = InteractorAuthorization()
  const { menuApplication } = InteractorMasterMenuApplication()
  const { notificationUnread } = InteractorManagementNotificationTotalUnread()

  useEffect(() => {
    if (!getItem('mgk-indentity')) {
      dispatch(ShowLoading())
      token()
    } else {
      if (account && !getItem('mgk-menu')) {
        dispatch(ShowLoading())
        menuApplication()
      }
      if (account && !getItem('mgk-notification-unread')) {
        notificationUnread()
      }
    }
  }, [account, dispatch, menuApplication, notificationUnread, token])

  return (
    <div className="App">
      {loading ? (
        <Loading cover="content" />
      ) : (
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme}>
          <Router>
            <Switch>
              <Route path="/" component={Routes} />
            </Switch>
            {account && <FirebaseNotification />}
          </Router>
        </ThemeSwitcherProvider>
      )}
    </div>
  )
}

export default App
