import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorWarehouseStockMoveSearch = () => {
  const stockMoveSearch = useCallback((body, callback, loading) => {
    const payload = { ...body }
    ApiService.jsonRequest('/warehouse/stock-move/search', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])

  return { stockMoveSearch }
}

export default InteractorWarehouseStockMoveSearch
