import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Loading } from 'components/Main'

export const Board = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path="/account" component={lazy(() => import(`./account`))} />
        <Route path="/company" component={lazy(() => import(`./company`))} />
        <Route path="/consumer" component={lazy(() => import(`./consumer`))} />
        <Route path="/transaction" component={lazy(() => import(`./transaction`))} />
        <Route path="/product" component={lazy(() => import(`./product`))} />
        <Route path="/warehouse" component={lazy(() => import(`./warehouse`))} />
        <Route path="/finance" component={lazy(() => import(`./finance`))} />
        <Route path="/administrator" component={lazy(() => import(`./administrator`))} />
        <Route path="/notification" component={lazy(() => import(`./notification`))} />
        <Route path="/tools" component={lazy(() => import(`./tools`))} />
        <Route path="/tower" component={lazy(() => import(`./tower`))} />
        <Route path="/" component={lazy(() => import(`../../containers/dashboard/Dashboard`))} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(Board)
