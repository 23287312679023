import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionOrderVerify = () => {
  const orderVerified = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/transaction/order-header/verify', payload, (response) => {
      if (response.code === 7200) {
        callback(response.data)
        loading(false)
        showAlert({
          icon: 'success',
          message: getTranslate('message.successfully_updated'),
          timer: 2000,
          reload: true,
        })
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
          redirect: '/transaction/order/manage',
        })
        loading(false)
      }
    })
  }, [])
  return { orderVerified }
}

export default InteractorTransactionOrderVerify
