import { MENU_LIST } from './action'
import { getItem } from '../../utility/Session'

const initialState = {
  menu_list: getItem('mgk-menu'),
}

export default function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case MENU_LIST:
      return {
        ...state,
        menu_list: action.data,
      }
    default:
      return state
  }
}
