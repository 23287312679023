import { FETCH_ACCOUNT } from './action'
import { getItem } from '../../utility/Session'

const initialState = {
  account: getItem('mgk-account'),
}

export default function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT:
      return {
        ...state,
        account: action.data,
      }
    default:
      return state
  }
}
