import axios from 'axios'
import { showAlert } from '../utility/SweetAlert'
import { getItem } from '../utility/Session'
import { getTranslate } from '../utility/MapperTranslate'
import { downloadFile } from 'utility/Helpers'

const ApiService = {
  jsonRequest: async (path, payload, callback) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getItem('mgk-indentity')
    const account = getItem('mgk-account')
    const headers = {
      'Content-Type': 'application/json',
    }
    if (bearer) {
      headers.Authorization = `Bearer ${bearer}`
    }

    const bodyRequest = {
      access: {
        geolocation: null,
        devices: null,
        application: process.env.REACT_APP_APPLICATION,
        corporate_uid: account?.account_information?.corporate_uid,
        corporate_code: account?.account_information?.corporate_code,
        account_role: account?.account_role,
        account_uid: account?.uid,
        account_name: account?.account_full_name,
      },
      payload,
    }
    const requestOptions = {
      method: 'POST',
      url,
      headers,
      data: bodyRequest,
    }
    axios(requestOptions)
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        const { status, statusText } = error.response
        if (statusText.toUpperCase() === 'UNAUTHORIZED' && status === 401) {
          showAlert({
            icon: 'warning',
            message: getTranslate('message.token_expired'),
            timer: 2000,
            reload: true,
          })
        }
        callback(error.response)
      })
  },
  formRequest: async (path, payload, callback, progress) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getItem('mgk-indentity')
    const account = getItem('mgk-account')
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${bearer}`,
    }
    let formdata = new FormData()
    const access = {
      geolocation: null,
      devices: null,
      application: process.env.REACT_APP_APPLICATION,
      corporate_uid: account?.account_information?.corporate_uid,
      corporate_code: account?.account_information?.corporate_code,
      account_role: account?.account_role,
      account_uid: account?.uid,
      account_name: account?.account_full_name,
    }
    formdata.append('access', JSON.stringify(access))
    Object.keys(payload).map((key) => formdata.append(key, payload[key]))
    const requestOptions = {
      method: 'POST',
      url,
      headers,
      data: formdata,
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        progress(percent)
        if (percent === 100) {
          setTimeout(() => progress(0), 1000)
        }
      },
    }
    axios(requestOptions)
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        const { status, statusText } = error.response
        if (statusText.toUpperCase() === 'UNAUTHORIZED' && status === 401) {
          showAlert({
            icon: 'warning',
            message: getTranslate('message.token_expired'),
            timer: 2000,
            reload: true,
          })
        }
        callback(error.response)
      })
  },
  downloadRequest: async (
    path,
    payload,
    filename,
    target_type,
    callback,
    setLoading = () => null,
  ) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getItem('mgk-indentity')
    const account = getItem('mgk-account')
    const headers = {
      'Content-Type': 'application/json',
    }
    if (bearer) {
      headers.Authorization = `Bearer ${bearer}`
    }
    const bodyRequest = {
      access: {
        geolocation: null,
        devices: null,
        application: process.env.REACT_APP_APPLICATION,
        corporate_uid: account?.account_information?.corporate_uid,
        corporate_code: account?.account_information?.corporate_code,
        account_role: account?.account_role,
        account_uid: account?.uid,
        account_name: account?.account_full_name,
      },
      payload,
    }
    const requestOptions = {
      method: 'POST',
      url,
      headers,
      data: bodyRequest,
      responseType: 'blob',
    }
    axios(requestOptions)
      .then((response) => new Blob([response.data], { type: target_type }))
      .then((blob) => {
        setLoading(false)
        downloadFile(blob, filename)
      })
      .catch((error) => {
        const { status, statusText } = error.response
        if (statusText.toUpperCase() === 'UNAUTHORIZED' && status === 401) {
          showAlert({
            icon: 'warning',
            message: getTranslate('message.token_expired'),
            timer: 2000,
            reload: true,
          })
        }
        callback(error.response)
        setLoading(false)
      })
  },
}

export default ApiService
