import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImgCrop from 'antd-img-crop'
import { PlusOutlined } from '@ant-design/icons'
import { Modal, Upload } from 'antd'
import { mimeType } from './FileExtensions'
import { InputWrapper } from '../style'
import 'antd/es/slider/style'
import {
  downloadByElement,
  findByArray,
  getBase64,
  getExtension,
  mappingMimeToExt,
  maximumUpload,
} from '../../../utility/Helpers'
import { confirmationAlert, showAlert } from '../../../utility/SweetAlert'
import { getTranslate } from '../../../utility/MapperTranslate'
import { InteractorStorageFileUpload, InteractorStorageFileDelete } from '../../../interactors/Main'

const FileUpload = ({ options, files, callback, disabled = false }) => {
  const { uploadFile } = InteractorStorageFileUpload()
  const { deleteFile } = InteractorStorageFileDelete()
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [allowedExtensions] = useState(mappingMimeToExt(options.mime_type, mimeType))
  const [maximumFileUpload] = useState(options.maximum_file_upload ?? 5)
  const [maximumFileSize] = useState(
    options.maximum_file_size_mb ?? process.env.REACT_APP_MAX_FILE_UPLOAD_MB,
  )

  const handlePreview = async (row) => {
    let file = row
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    const images_extensions = ['png', 'jpg', 'jpeg']
    if (images_extensions.includes(getExtension(file.url).toLowerCase())) {
      setPreviewImage(file.url || file.preview)
      setPreviewVisible(true)
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    } else {
      downloadByElement(file.url, file.name)
    }
  }

  const UploadButton = () => {
    return (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          {getTranslate('title.upload')}
        </div>
      </div>
    )
  }

  const handleUpload = async (file) => {
    const file_size = file.file.size
    const size_mb = file_size / 1024 / 1024
    if (file_size > 0 && size_mb > parseFloat(maximumFileSize)) {
      showAlert({
        icon: 'error',
        message: getTranslate(`validation.file_maximum|${maximumFileSize}|title.megabyte`),
        timer: 0,
      })
    } else {
      let fileNew = files
      fileNew.push({
        uid: '-1',
        name: file.file.name,
        percent: 1,
        status: 'uploading',
        url: URL.createObjectURL(file.file),
      })
      callback(fileNew)
      const index = fileNew.findIndex((obj) => obj.uid === '-1')
      // UPLOAD
      const payload = {
        category: options.category ?? 'OTHERS',
        file: file.file,
        reference_uid: options.reference_uid ?? '',
      }
      uploadFile(
        payload,
        (response) => {
          let fileServer = [...files]
          if (fileServer[index]?.status === 'uploading') {
            delete fileServer[index].percent
            if (response && Array.isArray(response)) {
              fileServer[index].uid = Math.round(new Date().getTime() / 1000).toString()
              fileServer[index].url = response[0].file_url
              fileServer[index].status = 'done'
            } else {
              fileServer.splice(index, 1)
            }
            callback(fileServer)
          }
        },
        (percentage) => {
          let fileLoading = [...files]
          if (fileLoading[index]?.percent < 100) {
            fileLoading[index].percent = percentage
            callback(fileLoading)
          }
        },
      )
    }
  }

  const beforeCrop = async (file) => {
    if (!allowedExtensions.includes(getExtension(file.name).toLowerCase())) {
      showAlert({
        icon: 'error',
        message: getTranslate(`validation.only_allowed_extensions|${allowedExtensions.join(', ')}`),
        timer: 0,
      })
      return false
    }
    if (maximumUpload({ file }, parseFloat(maximumFileSize))) {
      const images_extensions = ['png', 'jpg', 'jpeg']
      if (images_extensions.includes(getExtension(file.name).toLowerCase())) {
        return true
      }
      await handleUpload({ file })
      return false
    }
    return false
  }

  const handleRemove = (file) => {
    if (file.url) {
      confirmationAlert({
        message: getTranslate('message.confirm_delete'),
        icon: 'question',
      }).then((result) => {
        if (result === true) {
          deleteFile({ file_url: file.url }, (response) => {
            if (response) {
              const index = files.findIndex(({ url }) => url === file.url)
              if (index !== -1) {
                callback([...files.slice(0, index), ...files.slice(index + 1)])
              }
            }
          })
        }
      })
    }
  }
  return (
    <>
      <InputWrapper>
        <ImgCrop
          aspect={options.width / options.height}
          beforeCrop={beforeCrop}
          minZoom={0.2}
          cropperProps={{ restrictPosition: false }}
          fillColor="rgba(0, 0, 0, 0)"
        >
          <Upload
            accept={options.mime_type ?? '*'}
            listType={options.list_type ?? 'picture-card'}
            fileList={files}
            customRequest={handleUpload}
            onPreview={handlePreview}
            onRemove={handleRemove}
            disabled={disabled}
          >
            {!findByArray(files, 'status', 'uploading') ? (
              files.length >= maximumFileUpload ? null : (
                <UploadButton />
              )
            ) : null}
          </Upload>
        </ImgCrop>
        {options.required && options.required.minimum > files.length && (
          <span className="ant-form-item-explain-error">{options.required.message}</span>
        )}
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img
            alt=""
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </InputWrapper>
    </>
  )
}

FileUpload.propTypes = {
  options: PropTypes.any,
  files: PropTypes.any,
  callback: PropTypes.any,
  disabled: PropTypes.bool,
}

export default FileUpload
