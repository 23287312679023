import React, { useState, useEffect, useCallback } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { requestForToken, onMessageListener } from './FirebaseInitialize'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { InteractorSSOAccountToken } from 'interactors/Main'
import { useTranslation } from 'react-i18next'
import alertSound from '../assets/mp3/alert.mp3'

const FirebaseNotification = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { syncToken } = InteractorSSOAccountToken()
  const [notification, setNotification] = useState({ title: '', body: '' })
  const notify = () => toast(<ToastDisplay />)

  function ToastDisplay() {
    return (
      <div className="container-notif">
        <Link to={'/notification'} className="text-dark">
          <div className="row align-items-center">
            <div className="col-2">
              <i className="notification-icon"></i>
            </div>
            <div className="col-10 notification-title">
              <strong>
                <div>{notification?.title}</div>
              </strong>
              <div className="notificatin-body">{notification?.body}</div>
            </div>
          </div>
          <hr></hr>
          <div className="row align-items-center justify-content-end px-2 notification-bottom">
            <div>{t('title.view_more')}</div>
          </div>
        </Link>
      </div>
    )
  }

  const getToken = useCallback(async () => {
    const browser_token = await requestForToken()
    if (browser_token && browser_token !== account?.fcm_web) {
      syncToken(account, browser_token)
    }
  }, [account, syncToken])

  const notifySound = () => {
    let audio = new Audio(alertSound)
    audio.play()
  }

  useEffect(() => {
    getToken()

    if (notification?.title) {
      notifySound()
      notify()
    }
  }, [getToken, notification])

  if (onMessageListener) {
    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        })
      })
      .catch(() => {})
  }

  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        className: '',
        duration: 5000,
      }}
    />
  )
}

export default FirebaseNotification
