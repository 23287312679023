import { Button } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { getTranslate } from 'utility/MapperTranslate'
import { StyledModal } from './style'

const PopUpLabel = (props) => {
  const {
    buttonShow = false,
    buttonLabel = 'Open Modal',
    buttonClassName = '',
    callbackSuccess = () => {},
    callbackFail = () => {},
    children = <></>,
    modalTitle = '',
    confirmButtonName = getTranslate('button.print'),
    width = 'max(50%, 320px)',
    isModalOpen = false,
    setIsModalOpen = () => {},
    hasForm = false,
  } = props

  const handleOk = async () => {
    const result = (await callbackSuccess()) ?? true
    result && setIsModalOpen(false)
  }

  const handleCancel = () => {
    callbackFail()
    setIsModalOpen(false)
  }
  const showModal = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      {buttonShow && (
        <Button type="primary" onClick={showModal} className={`popup-btn ${buttonClassName}`}>
          {buttonLabel}
        </Button>
      )}
      <StyledModal
        title={modalTitle}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={confirmButtonName}
        width={width}
        forceRender={hasForm}
        footer={null}
      >
        {children}
      </StyledModal>
    </>
  )
}

PopUpLabel.propTypes = {
  buttonShow: PropTypes.bool,
  buttonClassName: PropTypes.string,
  buttonLabel: PropTypes.any,
  callbackSuccess: PropTypes.func,
  callbackFail: PropTypes.func,
  confirmButtonName: PropTypes.string,
  children: PropTypes.any,
  hasForm: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  modalTitle: PropTypes.any,
  setIsModalOpen: PropTypes.func,
  width: PropTypes.any,
}

export default PopUpLabel
