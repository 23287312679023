import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorTowerPdfDownload = () => {
  const downloadFile = useCallback((payload, path, filename, mimeType, callback, setLoading) => {
    ApiService.downloadRequest(path, payload, filename, mimeType, callback, setLoading)
  }, [])

  return { downloadFile }
}

export default InteractorTowerPdfDownload
